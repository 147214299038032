import React, { useEffect } from 'react';
import { Route, Routes, NavLink, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Home from './pages/Home';
import PricingTool from './pages/PricingTool';
import Dashboard from './pages/Dashboard';
import logo from '../src/components/files/comet-logo.png';
import { loginRequest } from './config/authConfig';

const Navigation = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const handleLogin = async () => {
    console.log("Attempting login...");
    try {
      const response = await instance.loginPopup(loginRequest);
      console.log("Login successful", response);
      navigate('/dashboard');
    } catch (e) {
      console.error("Login error: ", e);
    }
  };

  const handleLogout = () => {
    console.log("Attempting logout...");
    instance.logoutRedirect({
      postLogoutRedirectUri: "/home"
    })
      .then(() => {
        console.log("Logout redirect initiated successfully");
      })
      .catch(e => {
        console.error("Logout error: ", e);
      });
  };

  return (
    <nav className="container mx-auto px-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img src={logo} alt="comet Logo" className="h-12 mr-4" />
          <h1 className="text-xl font-bold">comet</h1>
        </div>
        <ul className="flex space-x-4 h-16 items-center">
          <li>
            <NavLink 
              to="/home" 
              className={({ isActive }) => 
                isActive ? "font-bold border-b-2 border-white pb-1" : "hover:text-teal-200"
              }
            >
              Home
            </NavLink>
          </li>
          {isAuthenticated && (
            <>
              <li>
                <NavLink 
                  to="/dashboard" 
                  className={({ isActive }) => 
                    isActive ? "font-bold border-b-2 border-white pb-1" : "hover:text-teal-200"
                  }
                >
                  Dashboard
                </NavLink>
              </li>
              {/* <li>
                <NavLink 
                  to="/pricing" 
                  className={({ isActive }) => 
                    isActive ? "font-bold border-b-2 border-white pb-1" : "hover:text-teal-200"
                  }
                >
                  Pricing Tool
                </NavLink>
              </li> */}
            </>
          )}
          <li>
            {isAuthenticated ? (
              <button onClick={handleLogout} className="bg-teal-600 hover:bg-teal-500 text-white font-bold py-2 px-4 rounded">
                Logout
              </button>
            ) : (
              <button onClick={handleLogin} className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded">
                Login
              </button>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
};

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const { instance } = useMsal();

  useEffect(() => {
    const checkAndSetActiveAccount = async () => {
      const currentAccount = instance.getActiveAccount();
      if (currentAccount) {
        console.log("Active account:", currentAccount);
      } else {
        console.log("No active account found");
        const accounts = await instance.getAllAccounts();
        if (accounts.length > 0) {
          instance.setActiveAccount(accounts[0]);
          console.log("Set active account:", accounts[0]);
        }
      }
    };

    checkAndSetActiveAccount();
  }, [instance]);

  return (
    <div className="min-h-screen flex flex-col">
      <header className="bg-black text-white shadow-md">          
        <Navigation />
      </header>

      <main className="flex-grow container mx-auto px-4 py-8">
        <Routes>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/home" element={<Home />} />
          <Route 
            path="/dashboard" 
            element={
              isAuthenticated ? (
                <Dashboard />
              ) : (
                <Navigate to="/home" state={{ from: location }} replace />
              )
            } 
          />
          <Route 
            path="/pricing/*" 
            element={
              isAuthenticated ? (
                <PricingTool />
              ) : (
                <Navigate to="/home" state={{ from: location }} replace />
              )
            } 
          />
        </Routes>
      </main>

      <footer className="bg-gray-200 text-center py-4">
        <p>☄️ Cloud Operations Management & Engineering Tool ✨</p>        
      </footer>
    </div>
  );
};

export default App;