import React from 'react';
import { Link } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { Card } from '../components/ui/card';
import { DollarSign, BarChart2, Shield, Zap } from 'lucide-react';

const Widget = ({ title, icon, link }) => (
  <Link to={link}>
    <Card className="flex items-center p-6 hover:bg-gray-50 transition-colors h-full">
      {icon}
      <h3 className="ml-4 text-lg font-medium text-gray-900">{title}</h3>
    </Card>
  </Link>
);

const Dashboard = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  // Get the user's name, fallback to 'User' if not available
  const userName = activeAccount ? (activeAccount.name || activeAccount.username || 'User') : 'User';

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">
        welcome to comet, {userName}
      </h1>
      <p className="mb-8 text-gray-600">Cloud Operations Management & Engineering Tool</p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <Widget 
          title="Pricing Tool" 
          icon={<DollarSign size={24} className="text-teal-500" />} 
          link="/pricing" 
        />
        <Widget 
          title="Analytics" 
          icon={<BarChart2 size={24} className="text-blue-500" />} 
          link="/analytics" 
        />
        <Widget 
          title="Security" 
          icon={<Shield size={24} className="text-red-500" />} 
          link="/security" 
        />
        <Widget 
          title="Performance" 
          icon={<Zap size={24} className="text-yellow-500" />} 
          link="/performance" 
        />
      </div>
    </div>
  );
};

export default Dashboard;