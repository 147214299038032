import React from 'react';

const Button = ({ children, onClick, disabled, className }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 disabled:opacity-50 ${className}`}
  >
    {children}
  </button>
);

export { Button };