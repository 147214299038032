import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: "4e7b8a40-e755-4207-a6d9-734c008759d1",
        authority: "https://login.microsoftonline.com/d9259343-0a45-4bcd-9f93-18d4ed950cdf",
        redirectUri: `${window.location.origin}`,
        postLogoutRedirectUri: "/home",
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
        allowNativeBroker: false,
    },
};

export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"]
};