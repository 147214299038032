import React from 'react';
// import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">welcome to comet</h1>
      <p className="mb-4">Cloud Operations Management & Engineering Tool.</p>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <section className="mb-8 bg-gray-50 p-4 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4">About Comet</h2>
          <p className="mb-4">Comet is a powerful Cloud Operations Management & Engineering Tool designed to streamline your cloud infrastructure and optimize performance.</p>
          <img src="/dashboard.png" alt="Comet Dashboard" className="w-full rounded-lg mb-4" />
        </section>

        <section className="mb-8 bg-gray-50 p-4 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4">Key Benefits</h2>
          <ul className="list-disc list-inside mb-4">
            <li>Centralised cloud management across multiple providers</li>
            <li>Real-time monitoring and alerting</li>
            <li>Cost optimization and resource allocation</li>
            <li>Automated scaling and performance tuning</li>
          </ul>
          <img src="/benefits.png" alt="Comet Benefits" className="w-full rounded-lg mb-4" />        
          </section>

        <section className="mb-8 bg-gray-50 p-4 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4">Features</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl font-semibold mb-2">Cloud Integration</h3>
              <p>Seamlessly connect and manage multiple cloud platforms.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Performance Analytics</h3>
              <p>Gain insights into your cloud infrastructure performance.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Security Management</h3>
              <p>Enhance your cloud security with built-in tools and best practices.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Cost Management</h3>
              <p>Optimize your cloud spending with detailed cost analysis.</p>
            </div>
          </div>
          <img src="/application.png" alt="Comet Features" className="w-full rounded-lg mt-4" />
        </section>      </div>
    </div>
  );
};

export default Home;