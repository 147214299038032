import React, { useState } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { Button } from '../../components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/ui/table';

const regions = [
  { value: 'eastus', label: 'East US' },
  { value: 'westus', label: 'West US' },
  { value: 'northeurope', label: 'North Europe' },
  { value: 'westeurope', label: 'West Europe' },
];

const CORS_PROXY = 'https://corsproxy.io/?';

const PublicIPPricing = () => {
  const [region, setRegion] = useState('');
  const [sku, setSku] = useState('');
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPrices = async () => {
    setLoading(true);
    setError(null);
    setPrices([]);

    const filterQuery = `$filter=armRegionName eq '${region}' and productName eq 'IP Addresses' and serviceName eq 'Virtual Network' and type eq 'Consumption' and contains(meterName, 'Static') eq true and skuName eq '${sku}'`;
    const url = `${CORS_PROXY}https://prices.azure.com/api/retail/prices?${filterQuery}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      
      if (data.Items && data.Items.length > 0) {
        setPrices(data.Items);
      } else {
        setError('No pricing data found for the specified criteria.');
      }
    } catch (error) {
      console.error('Error fetching prices:', error);
      setError(`Error fetching prices: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Public IP Pricing</h2>
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 mb-4">
        <Select value={region} onValueChange={setRegion}>
          <SelectTrigger className="w-full sm:w-[200px]">
            <SelectValue placeholder="Select Region" />
          </SelectTrigger>
          <SelectContent>
            {regions.map((reg) => (
              <SelectItem key={reg.value} value={reg.value}>
                {reg.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Select value={sku} onValueChange={setSku}>
          <SelectTrigger className="w-full sm:w-[200px]">
            <SelectValue placeholder="Select SKU" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="Standard">Standard</SelectItem>
            <SelectItem value="Basic">Basic</SelectItem>
          </SelectContent>
        </Select>        
        <Button 
          onClick={fetchPrices} 
          disabled={loading || !region || !sku}
          className="w-full sm:w-auto"
        >
          {loading ? 'Loading...' : 'Fetch Prices'}
        </Button>
      </div>
      
      {error && (
        <div className="text-red-500 mb-4">{error}</div>
      )}
      
      {prices.length > 0 && (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>SKU Name</TableHead>
              <TableHead>Meter Name</TableHead>
              <TableHead>Unit Price</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {prices.map((price, index) => (
              <TableRow key={index}>
                <TableCell>{price.skuName}</TableCell>
                <TableCell>{price.meterName}</TableCell>
                <TableCell>{price.unitPrice}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default PublicIPPricing;