import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { msalConfig } from './config/authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

// Component to handle auth-related side effects
const AuthProvider = ({ children }) => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        console.log("Login successful");
        const account = event.payload.account;
        instance.setActiveAccount(account);
        navigate('/dashboard');
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, navigate]);

  useEffect(() => {
    instance.handleRedirectPromise().catch(error => {
      console.error("Error handling redirect:", error);
    });
  }, [instance]);

  return children;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();