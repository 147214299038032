import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import ComputePricing from './ComputePricing';
import PublicIPPricing from './PublicIPPricing';
import StoragePricing from './StoragePricing';
import ManagedDiskPricing from './ManagedDiskPricing';

const resourceTypes = [
  { value: 'compute', label: 'Compute' },
  { value: 'publicip', label: 'Public IP' },
  { value: 'storage', label: 'Storage' },
  { value: 'manageddisk', label: 'Managed Disk' },
];

const PricingTool = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Azure Pricing Tool</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        {resourceTypes.map((type) => (
          <Link
            key={type.value}
            to={`/pricing/${type.value}`}
            className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded text-center"
          >
            {type.label}
          </Link>
        ))}
      </div>
      <Routes>
        <Route path="compute" element={<ComputePricing />} />
        <Route path="publicip" element={<PublicIPPricing />} />
        <Route path="storage" element={<StoragePricing />} />
        <Route path="manageddisk" element={<ManagedDiskPricing />} />
      </Routes>
    </div>
  );
};

export default PricingTool;